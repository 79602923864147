<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <v-card max-width="310" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="toolbar-title"> Alterar Senha </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="mt-2">
        <v-form>
          <v-text-field
            type="password"
            label="Senha Atual*"
            prepend-icon="fas fa-key"
            :autofocus="true"
            :disabled="loadButtonSubmit"
            v-model="data.current_password"
            :error-messages="errors.current_password"
          ></v-text-field>

          <v-text-field
            type="password"
            label="Nova Senha*"
            prepend-icon="fas fa-key"
            :disabled="loadButtonSubmit"
            v-model="data.new_password"
            :error-messages="errors.new_password"
          ></v-text-field>

          <v-text-field
            type="password"
            label="Confirmar Senha*"
            prepend-icon="fas fa-key"
            :disabled="loadButtonSubmit"
            v-model="data.confirm_new_password"
            :error-messages="errors.confirm_new_password"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          outlined
          color="primary"
          class="ma-2 white--text no-text-transform"
          @click="handleClickButtonCancel()"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          class="ma-2 white--text no-text-transform"
          :loading="loadButtonSubmit"
          @click="handleSubmitData()"
        >
          Alterar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "@/services/ToastService";
import AuthService from "@/services/Auth/AuthService";

export default {
  name: "ChangePassword",

  computed: {
    ...mapGetters({
      dialog: "getModalChangePassword",
    }),
  },

  data: () => ({
    data: {
      current_password: '',
    },
    errors: {
      current_password: '',
    },
    loadButtonSubmit: false,
    AuthService: new AuthService("/auth"),
  }),

  watch: {
    dialog: function (val) {
      if (val === false) {
        this.data = {};
        this.errors = {};
      }
    },
  },

  methods: {
    handleClickButtonCancel() {
      this.$store.dispatch("modalChangePassword", false);
    },

    async handleSubmitData() {
      try {
        this.errors = {};

        this.loadButtonSubmit = true;

        await this.AuthService.changePassword("change-password", this.data);

        ToastService("Senha alterada com sucesso!", "success");

        this.$store.dispatch("modalChangePassword", false);
      } catch (exception) {
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loadButtonSubmit = false;
      }
    },
  },
};
</script>

<style scoped>
.toolbar-title {
  margin-top: 25px;
  color: #0b6775;
  font-weight: bold;
  text-align: center;
  width: 100% !important;
}
</style>